












































import Component from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'
import Account from '@/api/models/Account'
import ErrorMixin from '@/shared/mixins/error'

@Component
export default class AccountDelete extends Mixins(ErrorMixin) {
    @Prop()
    private readonly account!: Account

    @Prop({ default: true })
    private readonly disabled!: boolean
}
