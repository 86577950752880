







































































































import Component from 'vue-class-component'
import { Mixins, Prop, Watch } from 'vue-property-decorator'
import Account from '@/api/models/Account'
import ErrorMixin from '@/shared/mixins/error'
import { Action } from 'vuex-class'

@Component({})
export default class AccountSettings extends Mixins(ErrorMixin) {
    @Prop()
    private readonly account!: Account

    @Prop({ default: true })
    private readonly disabled!: boolean

    @Action('updateAccount')
    updateAccountAction!: (acc: Account) => Promise<void>

    @Watch('account')
    onChildChanged () {
        this.refresh()
    }

    public opened = false
    public loading = false
    public errorMsg = ''
    public item: Account = { channel: 0, createdAt: '', name: '', token: '', updatedAt: '' }

    public mounted () {
        this.refresh()
    }

    public refresh () {
        if (this.account.name) {
            this.item = Object.assign({}, this.account)
        }
    }

    get commentLink (): string {
        return this.$t('docs.link.retailcrm') +
            '/Users/Chats/ConnectionOfMessengersAndChats/FacebookMessenger/FacebookComments#h-0'
    }

    get igCommentLink (): string {
        return this.$t('docs.link.retailcrm') +
            '/Users/Chats/ConnectionOfMessengersAndChats/InstagramMessenger/IntegrationWithInstagramMessenger#h-1'
    }

    public async save () {
        this.loading = true
        try {
            await this.updateAccountAction(this.item)
            this.opened = false
        } catch (err) {
            this.errorMsg = this.getErrorText(err)
        } finally {
            this.loading = false
        }
    }

    public close () {
        this.refresh()
        this.opened = false
    }
}
