
































































import { Component, Prop, Mixins } from 'vue-property-decorator'
import Account from '@/api/models/Account'
import AccountHistoryUpload from '@/modules/settings/components/AccountHistoryUpload.vue'
import AccountSettings from '@/modules/settings/components/AccountSettings.vue'
import AccountDelete from '@/modules/settings/components/AccountDelete.vue'
import AccountLogs from '@/modules/settings/components/AccountLogs.vue'
import { Getter } from 'vuex-class'
import Connection from '@/api/models/Connection'
import ErrorMixin from '@/shared/mixins/error'

@Component({
    components: {
        AccountLogs,
        AccountHistoryUpload,
        AccountSettings,
        AccountDelete
    }
})
export default class AccountsList extends Mixins(ErrorMixin) {
    @Getter('connection')
    connection?: Connection;

    @Prop({ default: () => [] })
    private readonly accounts!: Array<Account>

    @Prop({ default: false })
    private readonly loading!: boolean

    @Prop({ default: () => [] })
    private readonly historyUploads!: boolean[]

    public accountAvailable (acc: Account): boolean {
        return (this.connection?.active || false) && (acc.status || false)
    }

    get connectionActive (): boolean {
        return this.connection?.active || false
    }
}
