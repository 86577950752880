import Vue from 'vue'
import axios, { AxiosError } from 'axios'
import { Component } from 'vue-property-decorator'

@Component
export default class ErrorMixin extends Vue {
    public getErrorText (
        error: Error | AxiosError | undefined,
        fallback = 'unexpectedError',
        critical = 'internalError'
    ): string {
        if (!axios.isAxiosError(error) || error == null) {
            return this.$t(fallback).toString()
        }

        // @ts-ignore
        if (error.response?.status >= 500) {
            return this.$t(critical).toString()
        }

        const err = error.response?.data?.error

        if (err != null) {
            if (Array.isArray(err)) {
                if (err.length === 0) {
                    return this.$t(fallback).toString()
                }

                return err[0]
            }

            return err
        }

        return this.$t(fallback).toString()
    }
}
