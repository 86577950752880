




























































import Component from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'
import Account from '@/api/models/Account'
import AccountLog from '@/api/models/AccountLog'
import { Action } from 'vuex-class'
import ErrorMixin from '@/shared/mixins/error'

@Component
export default class AccountLogs extends Mixins(ErrorMixin) {
    @Prop()
    private readonly account!: Account

    @Prop({ default: true })
    private readonly disabled!: boolean

    @Action('loadLogs')
    loadLogsAction!: (acc: Account) => Promise<AccountLog[]>

    public opened = false
    public loading = true
    public errorMsg = ''
    public logs: AccountLog[] = []

    public async loadLogs () {
        this.opened = true
        this.loading = true
        try {
            this.logs = await this.loadLogsAction(this.account)
        } catch (err) {
            this.errorMsg = this.getErrorText(err)
        } finally {
            this.loading = false
        }
    }
}
