

























































import { Component, Mixins } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import BaseLayout from '@/shared/components/BaseLayout.vue'
import AccountsList from '@/modules/settings/components/AccountsList.vue'
import Account from '@/api/models/Account'
import CreateAccountRequest from '@/api/models/CreateAccountRequest'
import ErrorMixin from '@/shared/mixins/error'
import FbButton from '@/modules/settings/components/FbButton.vue'
import SettingsResponse from '@/api/models/SettingsResponse'
import Connection from '@/api/models/Connection'
import FbForm from '@/modules/settings/components/FbForm.vue'
import AddPagesRequest from '@/api/models/AddPagesRequest'
import PostMessages from '@/shared/frame'
import AccountSelector from '@/modules/settings/components/AccountSelector.vue'
import FBPaginatedResponse from '@/shared/models/FBPaginatedResponse'
import { FBPage } from '@/shared/models/FBPage'
import facebookService from '@/api/services/FacebookService'

export type FB = fb.FacebookStatic
export type StatusResponse = fb.StatusResponse

@Component({
    components: {
        AccountSelector,
        FbForm,
        AccountsList,
        BaseLayout,
        FbButton
    }
})
export default class Accounts extends Mixins(ErrorMixin) {
    @Getter('accounts')
    public accounts!: Array<Account>

    @Getter('connection')
    connection?: Connection

    @Action('addAccountOAuth')
    private addAccountOAuthAction!: (acc: AddPagesRequest) => Promise<void>

    @Action('loadSettings')
    private loadSettings!: () => Promise<SettingsResponse>

    @Action('logout')
    logoutAction!: (conn?: Connection) => Promise<Connection>

    @Action('fbLogin')
    fbLoginAction!: (permissions: string[]) => Promise<StatusResponse>

    @Action('fbLogout')
    fbLogoutAction!: () => Promise<void>

    @Action('addAccount')
    addAccountAction!: (acc: CreateAccountRequest) => Promise<void>

    @Action('enableOAuth')
    enableOAuthAction!: (conn?: Connection) => Promise<void>

    @Action('deleteAccount')
    private deleteAccountAction!: (acc: Account) => Promise<void>

    get isConnectionActive (): boolean {
        return !!this.connection?.active
    }

    private loading = true
    private logged = false
    private errorMsg = ''
    public permissions = ['']
    public historyUploads: boolean[] = []
    public fbAvailable = true
    private fbPages: Array<FBPage> = []
    private nextPage = ''
    private previousPage = ''
    private loadingNextPage = false
    private loadingPreviousPage = false
    private account: AddPagesRequest = {
        token: '',
        profileId: '',
        connectionId: 0,
        pages: []
    }

    public async mounted () {
        const settings = await this.loadSettings()
        this.permissions = settings.permissions
        this.historyUploads = settings.historyUploads

        await facebookService.init(settings.appID).then(
            null,
            () => {
                this.fbAvailable = false
                this.loading = false
            }
        )

        await facebookService.isLogged().then(
            result => {
                if (result === null) {
                    this.fbAvailable = false
                    this.loading = false
                } else {
                    this.logged = result
                    this.loading = false
                }
            },
            () => {
                this.fbAvailable = false
                this.loading = false
            }
        )
    }

    public async reset () {
        this.loading = true

        this.fbLoginAction(this.permissions).then(async resp => {
            PostMessages.createPostMessageEvent('fb-oauth-login')

            if (resp.status === 'connected') {
                this.account.token = resp.authResponse.accessToken
                this.account.profileId = resp.authResponse.userID
                this.account.connectionId = this.connection?.id || 0
                this.logged = true
                this.loadAccountSelector()

                return
            }

            this.loading = false
        })
    }

    public async login (resp: StatusResponse) {
        if (resp === undefined) {
            return
        }

        this.loading = true
        PostMessages.createPostMessageEvent('fb-oauth-login')

        if (resp.status === 'connected') {
            this.account.token = resp.authResponse.accessToken
            this.account.profileId = resp.authResponse.userID
            this.account.connectionId = this.connection?.id || 0
            this.logged = true
            this.loadAccountSelector()

            return
        }

        this.loading = false
    }

    public async logout () {
        this.loading = true
        await this.fbLogoutAction().then(async () => {
            try {
                await this.logoutAction(this.connection)
                this.logged = false
            } catch (err) {
                this.errorMsg = err
            } finally {
                this.loading = false
            }
        })
    }

    public async resumeConnect (pages: Array<FBPage>) {
        try {
            this.loading = true
            this.account.pages = pages;
            (this.$refs.accountSelector as AccountSelector).setPopupState(false)
            await this.addAccountOAuthAction(this.account)

            PostMessages.createPostMessageEvent('fb-connected')
        } catch (err) {
            this.errorMsg = err.response?.data?.error ?? this.$t('internalError').toString()
            PostMessages.createPostMessageEvent('fb-connect-error', { message: this.errorMsg })
        } finally {
            this.loading = false
        }
    }

    public async addAccount (account: CreateAccountRequest) {
        this.loading = true

        try {
            account.connectionId = this.connection?.id || 0
            await this.addAccountAction(account)
        } catch (err) {
            this.errorMsg = err
        } finally {
            this.loading = false
        }
    }

    public async enableOAuth () {
        this.loading = true

        try {
            await this.enableOAuthAction(this.connection)
        } catch (err) {
            this.errorMsg = err
        } finally {
            this.loading = false
        }
    }

    public async deleteAccount (acc: Account) {
        this.loading = true
        try {
            await this.deleteAccountAction(acc)
        } catch (err) {
            this.$emit('error', this.getErrorText(err))
        } finally {
            this.loading = false
        }
    }

    public loadAccountSelector () {
        if (typeof FB === 'undefined') {
            return
        }

        this.loading = true

        /* eslint-disable-next-line */
        FB.api(
            '/me/accounts?fields=id,name,connected_instagram_account{id,ig_id,username}&limit=15',
            (list: FBPaginatedResponse) => {
                this.loading = false

                if ((list.error?.message || '').length > 0) {
                    this.$emit('error', list.error?.message)
                    return
                }

                this.fbPages = list.data as Array<FBPage>
                this.nextPage = list.paging?.cursors?.after || ''
                this.previousPage = list.paging?.cursors?.before || '';
                (this.$refs.accountSelector as AccountSelector).setPopupState(true)
            }
        )
    }

    public nextAccountsPage () {
        if (typeof FB === 'undefined') {
            return
        }

        if (this.nextPage === '') {
            return
        }

        this.loadingNextPage = true

        /* eslint-disable-next-line */
        FB.api(
            '/me/accounts?fields=id,name,connected_instagram_account{id,ig_id,username}&limit=15&after=' + this.nextPage,
            (list: FBPaginatedResponse) => {
                this.loadingNextPage = false

                if ((list.error?.message || '').length > 0) {
                    this.$emit('error', list.error?.message)
                    return
                }

                if (list.data == null || !Array.isArray(list.data) || list.data.length === 0) {
                    return
                }

                this.fbPages = list.data as Array<FBPage>
                this.nextPage = list.paging?.cursors?.after || ''
                this.previousPage = list.paging?.cursors?.before || ''
            }
        )
    }

    public previousAccountPage () {
        if (typeof FB === 'undefined') {
            return
        }

        if (this.previousPage === '') {
            return
        }

        this.loadingPreviousPage = true

        /* eslint-disable-next-line */
        FB.api(
            '/me/accounts?fields=id,name,connected_instagram_account{id,ig_id,username}&limit=15&before=' + this.previousPage,
            (list: FBPaginatedResponse) => {
                this.loadingPreviousPage = false

                if ((list.error?.message || '').length > 0) {
                    this.$emit('error', list.error?.message)
                    return
                }

                if (list.data == null || !Array.isArray(list.data) || list.data.length === 0) {
                    return
                }

                this.fbPages = list.data as Array<FBPage>
                this.nextPage = list.paging?.cursors?.after || ''
                this.previousPage = list.paging?.cursors?.before || ''
            }
        )
    }
}
