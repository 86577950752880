















































import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
export type StatusResponse = fb.StatusResponse

@Component
export default class FbButton extends Vue {
    @Prop({ default: false })
    private readonly loading!: boolean

    @Prop({ default: false })
    private readonly auth!: boolean

    @Prop({ default: () => [] })
    private readonly permissions!: string[]

    @Prop({ type: Boolean, default: false })
    private readonly connectionActive!: boolean

    @Prop({ default: false })
    public readonly isIFrame!: boolean

    public get isShowManageButtons (): boolean {
        return !this.loading && this.auth
    }

    public get isShowInActiveConnectionMessage (): boolean {
        return !this.connectionActive && !this.auth
    }

    public get isShowInActiveConnectionMessageAndButton (): boolean {
        return !this.connectionActive && this.auth
    }

    public get isShowFbButton (): boolean {
        return !this.loading && !this.auth
    }

    public mounted () {
        window.loginHandler = this.loginHandler
    }

    public loginHandler (resp: StatusResponse) {
        this.$emit('login', resp)
    }
}
