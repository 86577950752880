function getBrandKey (url: string): string {
    return url.length === 0
        ? process.env.VUE_APP_BRAND_KEY || ''
        : url.includes('.retailcrm.') ? 'retailcrm' : 'simla'
}

function setFaviconByBrand (brand: string): void {
    const elements = [
        {
            id: 'shortcut_favicon',
            value: '/favicon/favicon.ico'
        },
        {
            id: 'favicon',
            value: '/favicon/favicon.svg'
        },
        {
            id: 'apple_favicon',
            value: '/favicon/apple-touch-icon.png'
        },
        {
            id: 'manifest_favicon',
            value: '/favicon/manifest.json'
        }
    ]

    elements.forEach((element) => {
        const shortcutFavicon = document.getElementById(element.id)
        if (shortcutFavicon) {
            const shortcutFaviconHref = shortcutFavicon.attributes.getNamedItem('href')
            if (shortcutFaviconHref) {
                shortcutFaviconHref.value =
                    (process.env.VUE_APP_CDN || '//s3-s1.retailcrm.tech/eu-central-1/retailcrm-static') +
                    /branding/ + brand + element.value
            }
        }
    })
}

export {
    getBrandKey,
    setFaviconByBrand
}
