
























import Component from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'
import Account from '@/api/models/Account'
import ErrorMixin from '@/shared/mixins/error'
import { Action, Mutation } from 'vuex-class'
import { RootStateMutations } from '@/store/mutations'

@Component
export default class AccountHistoryUpload extends Mixins(ErrorMixin) {
    @Prop()
    private readonly account!: Account

    @Prop({ default: true })
    private readonly disabled!: boolean

    @Prop({ default: () => [] })
    private readonly historyUploads!: boolean[]

    @Action('uploadHistory')
    uploadHistoryAction!: (channel: number) => Promise<void>

    @Mutation(RootStateMutations.updateAccount)
    updateAccountMutation!: (acc: Account) => void

    public async fbUploadHistory () {
        this.$emit('loading', true)
        try {
            await this.uploadHistoryAction(this.account.channel)
            this.updateAccountMutation(Object.assign({}, this.account, { fbHistoryUploaded: true }))
        } catch (err) {
            this.$emit('error', this.getErrorText(err))
        } finally {
            this.$emit('loading', false)
        }
    }

    public async igUploadHistory () {
        this.$emit('loading', true)
        try {
            await this.uploadHistoryAction(this.account.igChannel || 0)
            this.updateAccountMutation(Object.assign({}, this.account, { igHistoryUploaded: true }))
        } catch (err) {
            this.$emit('error', this.getErrorText(err))
        } finally {
            this.$emit('loading', false)
        }
    }
}
