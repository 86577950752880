


























































































import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import CreateAccountRequest from '@/api/models/CreateAccountRequest'

@Component
export default class FbForm extends Vue {
    @Prop({ default: false })
    private readonly loading!: boolean

    @Prop({ default: false })
    private readonly auth!: boolean

    @Prop({ default: '' })
    private readonly errorMsg!: string

    public opened = false
    public account: CreateAccountRequest = {
        token: '',
        name: '',
        connectionId: 0
    }

    public close () {
        this.account = {
            token: '',
            name: '',
            connectionId: 0
        }
        this.opened = false
    }
}
