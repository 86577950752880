

























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ConnectedInstagram, FBPage } from '@/shared/models/FBPage'
import { Dictionary } from 'vue-router/types/router'
import Account from '@/api/models/Account'

interface Choice<T> {
    item: T;
    state: boolean;
}

@Component
export default class AccountSelector extends Vue {
    @Prop({ default: false })
    private readonly loading!: boolean

    @Prop({ default: [] })
    private readonly accounts!: Array<FBPage>

    @Prop({ default: [] })
    private readonly currentAccounts!: Array<Account>

    @Prop({ default: false })
    private hasNextPage!: boolean;

    @Prop({ default: false })
    private hasPreviousPage!: boolean;

    @Prop({ default: false })
    private loadingNextPage!: boolean;

    @Prop({ default: false })
    private loadingPreviousPage!: boolean;

    public choices: Dictionary<FBPage> = {}
    public fbChosen: Dictionary<Choice<FBPage>> = {}
    public igChosen: Dictionary<Choice<ConnectedInstagram|undefined>> = {}
    public opened = false

    @Watch('accounts', { deep: true })
    onAccountsChange (val: Array<FBPage>): void {
        for (const acc of this.currentAccounts) {
            if (!acc.pageId) {
                continue
            }

            const id = acc.pageId || ''
            const ig = this.extractIgAccount(acc)

            if (!(id in this.fbChosen)) {
                this.$set(this.fbChosen, acc.pageId || '', {
                    item: {
                        id: acc.pageId,
                        name: acc.name,
                        connected_instagram_account: ig /* eslint-disable-line */
                    } as FBPage,
                    state: true
                })
            }

            if (ig && !(id in this.igChosen)) {
                this.$set(this.igChosen, acc.pageId || '', {
                    item: ig,
                    state: true
                })
            }
        }

        for (const item of val) {
            if (!(item.id in this.fbChosen)) {
                this.$set(this.fbChosen, item.id, { item, state: false })
            }

            if (item.connected_instagram_account) {
                if (!(item.id in this.igChosen)) {
                    this.$set(this.igChosen, item.id, {
                        item: item.connected_instagram_account,
                        state: false
                    })
                }

                if (!this.fbChosen[item.id].item.connected_instagram_account) {
                    this.$set(this.fbChosen, item.id, {
                        item: {
                            ...item,
                            connected_instagram_account: item.connected_instagram_account /* eslint-disable-line */
                        },
                        state: this.fbChosen[item.id].state
                    })
                }
            }
        }
        this.choices = val.reduce((acc, page) => {
            acc[page.id] = page
            return acc
        }, {} as Dictionary<FBPage>)
        this.$forceUpdate()
    }

    public setPopupState (state: boolean): void {
        this.opened = state
    }

    public chosenPages (): Array<FBPage> {
        return Object.values(this.fbChosen)
            .filter(choice => choice.state)
            .map(choice => {
                if (
                    choice.item.connected_instagram_account &&
                    (!(choice.item.id in this.igChosen) || !this.igChosen[choice.item.id].state)
                ) {
                    return {
                        id: choice.item.id,
                        name: choice.item.name
                    }
                }
                return choice.item
            })
    }

    private extractIgAccount (acc: Account): ConnectedInstagram | undefined {
        if (!acc.igUsername || !acc.igAccountId || !acc.igInternalAccountId) {
            return undefined
        }
        return {
            id: acc.igAccountId || '',
            ig_id: +acc.igInternalAccountId, /* eslint-disable-line */
            username: acc.igUsername || ''
        }
    }
}
