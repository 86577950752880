






































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import Connection from '@/api/models/Connection'
import { getBrandKey, setFaviconByBrand } from '@/shared/helpers'
import { setAuthToken } from '@/api/service'
import FbButton from '@/modules/settings/components/FbButton.vue'
import { RootStateMutations } from '@/store/mutations'

@Component({
    components: { FbButton }
})
export default class BaseLayout extends Vue {
    private brand = process.env.VUE_APP_BRAND_KEY

    @Getter('isLoading')
    readonly loading!: boolean

    @Getter('connection')
    connection!: Connection

    @Action('loadConnection')
    loadConnection!: () => void

    @Prop({ default: false })
    public readonly fbLoading!: boolean

    @Mutation(RootStateMutations.setLoading)
    setLoading!: (val: boolean) => void

    public isFrame = false

    get logoPath (): string {
        return (process.env.VUE_APP_CDN || '//s3-s1.retailcrm.tech/eu-central-1/retailcrm-static') +
            '/branding/' + getBrandKey(this.connection.apiUrl) + '/logo/logo_icon.svg'
    }

    get docsLink (): string {
        return this.$t('docs.link.' + this.brand) +
            '/Users/Chats/ConnectionOfMessengersAndChats/FacebookMessenger'
    }

    get systemLink (): string {
        return this.connection.publicUrl && this.connection.publicUrl.length > 0 ? this.connection.publicUrl : ''
    }

    get integrationsLink (): string {
        return this.systemLink ? `${this.systemLink}/admin/integration/list` : '#'
    }

    get moduleLink (): string {
        return this.systemLink
            ? `${this.systemLink}/admin/integration/${this.$t('integrationCode').toString()}/edit`
            : '#'
    }

    async created (): Promise<void> {
        if (window.self !== window.top) {
            this.isFrame = true
            // init sendMessages - in wrapper
            // send ready message
            // bind events
        }

        if (this.connection.apiUrl.length > 0) {
            this.updateFavicon()
            return
        }

        if (this.$route.params.clientID) {
            setAuthToken(this.$route.params.clientID as string)
        }

        try {
            this.setLoading(true)
            await this.loadConnection()
            this.updateFavicon()
            this.setLoading(false)
        } catch (e) {
            await this.$router.push('/')
        }
    }

    private updateFavicon () {
        if (this.connection.apiUrl.length > 0) {
            this.brand = getBrandKey(this.connection.apiUrl)
            setFaviconByBrand(this.brand)
        }
    }
}
